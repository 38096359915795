<template>
  <div :class="{'notice-height': pwdShow}">
    <div class="notice" v-if="pwdShow">
      <div class="notice-main">
        <div class="notice-icon">
          <a-icon type="exclamation" />
        </div>
        <div class="notice-text">您的密码还剩余<span>{{ odd }}</span>天过期，为了您的账号可以正常使用，建议您尽快修改密码。</div>
      </div>
      <!-- 系统公告 -->
      <div class="close close-pwd">
        <span @click="clickEditPassword">修改密码</span>
        <a-icon type="close-square" @click="pwdClose" />
      </div>
    </div>
    <div v-for="(item,index) in dataList" :key="index" class="item" @click="selectSite(item)">
      <div class="headerView">
        <div class="project-name">
          项目编号:
        </div>
        <div class="label font">{{ item.no }}</div>
        <!-- <div>
          Site: <span class="font">{{ item.orgNo }}</span>
        </div> -->
        <div class="right">
          <span class="pro-status" :class="item.status | enumValueByKey($const.PROJECT_STATUS,'style')" >{{ item.status | enumValueByKey($const.PROJECT_STATUS) }}</span>
        </div>
      </div>
      <div class="titleView">{{ item.name }}</div>
      <!-- <div class="bottomView">
        参与者: <span class="large">{{ item.allNum || 0 }}</span> / <span class="large">{{ item.noSignNum || 0 }}</span>人待签署
      </div> -->
    </div>
    <!--    <div v-if="noData" class="empty-view">-->
    <!--      &#45;&#45; 没有更多数据了 &#45;&#45;-->
    <!--    </div>-->
    <div class="no-data" v-if="!dataList.length">
      <img src="/static/img/empty.png">
      <p>列表为空<br>当前列表暂无内容</p>
    </div>
    <div v-if="isLoading" class="shipClass">
      <a-spin />
    </div>
    <a-modal
      width="500px"
      title="请选择临床研究中心"
      :visible="siteVisible"
      :footer="null"
      @cancel="siteVisible = false"
    >
      <div v-for="(item,index) in orgList" :key="index" class="site-view" @click="siteSelect(item)">
        {{ item.researchCenterNo }}<span style="margin-left:20px" >{{ item.orgName }}</span>
      </div>
    </a-modal>
    <setPwd ref="pwdRef"></setPwd>
  </div>
</template>
<script>
import { getProjectList, Prompt } from '@/api/overview/overview'
import setPwd from '@/components/GlobalHeader/setPwd'
export default {
  components: { setPwd },
  data () {
    return {
      dataList: [],
      isLoading: false,
      pageNumber: 1,
      pageSize: 10,
      pageCount: 1,
      noData: false,
      siteVisible: false,
      orgList: [],
      recordDetail: {},
      pwdShow: false,
      odd: null,
      isRestPwd: null
    }
  },
  mounted () {
    this.isRestPwd = localStorage.getItem('isRestPwd')
    if (Number(this.isRestPwd) === 0 || Number(this.isRestPwd) === 2) {
      this.$refs.pwdRef.open(this.isRestPwd)
    }
    this.getPrompt()
    this.getProjectList()
    const _this = this
    window.onscroll = function () {
      // 变量scrollTop是滚动条滚动时，距离顶部的距离
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      // 变量windowHeight是可视区的高度
      var windowHeight = document.documentElement.clientHeight || document.body.clientHeight
      // 变量scrollHeight是滚动条的总高度
      var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
        // 滚动条到底部的条件
        if (scrollTop + windowHeight === scrollHeight) {
          _this.pageNumber++
          if (_this.pageNumber <= _this.pageCount) {
            _this.getProjectList()
          } else {
            _this.noData = true
          }
        }
      }
  },
  methods: {
    getProjectList () {
      this.isLoading = true
      getProjectList({ pageNumber: this.pageNumber, pageSize: this.pageSize }).then(res => {
        if (res.content && res.content.length) {
          this.dataList.push(...res.content)
          this.pageCount = res.pageCount
          if (this.pageNumber === 1 && res.content.length < this.pageSize) {
            this.noData = true
          }
        }
      }).finally(() => {
        this.isLoading = false
      })
    },
    // 跳转研究中心选择
    selectSite (record) {
      this.recordDetail = record
      this.orgList = []
      if (record.orgList) {
        record.orgList.forEach(item => {
          if (Number(item.status) === 1) {
            this.orgList.push(item)
          }
        })
      }
      if (this.orgList && this.orgList.length === 1) {
        // 只有一个临床研究中心
        this.toDetail(record.orgList[0])
      } else if (this.orgList && this.orgList.length > 1) {
        // 弹窗选择
        this.siteVisible = true
      } else {
        this.$notification.error({
          message: '当前用户还没有分配site'
        })
      }
    },
    // 临床研究中心选择事件
    siteSelect (item) {
      // 该CRC是否在该研究中心下
      if (Number(item.status) === 1) {
        this.toDetail(item)
        this.siteVisible = false
      } else {
        this.$message.error('该crc暂不在该研究中心下!')
      }
    },
    toDetail (item) {
      this.$store.dispatch('EnterProject', this.recordDetail).then(res => {
        this.$router.push({
          name: 'Detail',
          query: {
            id: this.recordDetail.id,
            orgId: item.orgId,
            researchId: item.researchId
          }
        })
      })
    },
    // 修改密码
    clickEditPassword () {
      this.$refs.pwdRef.open()
    },
    // 获取修改密码提醒
    async getPrompt () {
      const res = await Prompt()
      const odd = localStorage.getItem('odd')
      if (res && res !== odd && res > 0) {
        this.pwdShow = true
        this.odd = res
      } else {
        this.pwdShow = false
      }
    },
    // 关闭公告
    pwdClose () {
      localStorage.setItem('odd', this.odd)
      this.pwdShow = false
    }
  }
}
</script>
<style lang="less" scoped>
  .empty-view{
    text-align: center;
    font-size: 16px;
  }
  .shipClass {
    text-align: center;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 30px 50px;
    margin: 20px 0;
  }
  .item{
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eaeaea;
    .headerView{
      display: flex;
      font-size: 20px;
      .project-name {
        font-size: 22px;
        font-weight: 600;
        color: #000;
      }
      .label{
        width: 30%;
        word-break: break-all;
        word-wrap: break-word;
      }
      .font{
        color: #0099FF;
        font-weight: 500;
        margin-left: 5px;
      }
      .right{
        text-align: right;
        flex: 1;
      }
    }
    .titleView{
      margin-top: 5px;
      font-size: 22px;
      line-height: 36px;
      font-weight: 600;
      color: #000;
      word-break: break-all;
      word-wrap: break-word;
    }
    .bottomView{
      margin-top: 10px;
      font-size: 16px;
      .large{
        font-size: 25px;
        font-weight: 500;
      }
    }
  }
  .site-view{
    margin-bottom: 15px;
    border-radius: 5px;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    color: #fff;
    padding: 0 15px;
    background: #0099FF;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .no-data {
    text-align: center;
    padding-top: 100px;
    img {
      width: 300px;
      height: 300px;
    }
    p {
      font-size: 24px;
      line-height: 32px;
      color: #B5BBC6;
      margin-top: 20px;
    }
  }
  .notice-height {
    padding-top: 45px;
  }
  .notice {
    width: 100%;
    background: #FFFBE6;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    overflow: hidden;
    position: fixed;
    top: 64px;
    left: 0;
    .notice-main {
      width: 100%;
      display: flex;
      align-items: center;
      overflow: hidden;
    }
    .notice-icon {
      width: 20px;
      height: 20px;
      font-size: 16px;
      background: #F9B82E;
      color: #fff;
      border-radius: 50%;
      text-align: center;
      line-height: 20px;
    }
    .notice-text {
      flex: 1;
      padding: 0 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      span {
        font-size: 16px;
        color: #FFAD0D;
        margin: 0 6px;
        font-weight: bold;
      }
    }
    .close {
      font-size: 16px;
      color: #999;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .close-pwd {
      width: 120px;
      span {
        font-size: 14px;
        color: #43D8D2;
        cursor: pointer;
      }
    }
  }
</style>
